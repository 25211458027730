import * as Sentry from '@sentry/sveltekit';
import { hotjarInit } from './lib/utils/hotjar';

hotjarInit();

const IGNORE_ERRORS = [/The email or password you have entered is incorrect/i];

Sentry.init({
	dsn: 'https://301f36138b8fb5eadb575346186d3219@o968153.ingest.us.sentry.io/4507946504421376',
	tracesSampleRate: 0.25,
	environment: process.env.NODE_ENV ?? 'development',
	// @ts-ignore
	release: __RELEASE_NAME__,
	normalizeDepth: 6,
	integrations: [
		Sentry.thirdPartyErrorFilterIntegration({
			filterKeys: ['testing-key'],
			behaviour: 'apply-tag-if-contains-third-party-frames'
		})
	],
	debug: true,
	ignoreErrors: IGNORE_ERRORS,
	allowUrls: [/https?:\/\/(?:www\.|web\.dev\.)?abcmouse\.com/],
	beforeSend(event) {
		if (event.exception && Array.isArray(event.exception.values)) {
			const isNotFatal = event.exception.values.some(
				(ex) => ex.type && ex.type?.fatal !== 'undefined' && !ex.type.fatal
			);

			if (isNotFatal) {
				return null;
			}
		}
		return event;
	}
});

export const handleError = Sentry.handleErrorWithSentry();
